<template>
  <div class="case-list-container">
    <div class="case-list-area">
      <div class="case-list-top">
        <div class="new-case-btn">
          <a-button @click="clickCreateCase" :disabled="isEnableCreateCase">
            <template #icon><UserAddOutlined /></template>
            {{ $t('LM001.createCase') }}
          </a-button>
        </div>
        <!-- <div class="import-btn">
          <a-button>
            <template #icon><CloudUploadOutlined /></template>
            {{ $t('LM001.caseImport') }}
          </a-button>
        </div>
        <div class="example-btn">
          <a-button>
            <template #icon><CloudDownloadOutlined /></template>
            {{ $t('LM001.formExample') }}
          </a-button>
        </div> -->
        <div class="group-btn">
          <a-button
            @click="goToGroupManagement"
            :disabled="isEnableGroupManagement"
          >
            <template #icon><TeamOutlined /></template>
            {{ $t('LM001.groupManagerment') }}
          </a-button>
        </div>
        <div class="filter-btn">
          <a-button
            :class="filterStatus === 'Activate' ? 'check-btn' : 'uncheck-btn'"
            @click="onFilter('Activate')"
          >
            <template v-if="filterStatus === 'Activate'" #icon>
              <CheckCircleOutlined />
            </template>
            {{ $t('LM001.activate') }}
          </a-button>
          <a-button
            :class="filterStatus === 'Suspended' ? 'check-btn' : 'uncheck-btn'"
            @click="onFilter('Suspended')"
          >
            <template v-if="filterStatus === 'Suspended'" #icon>
              <CheckCircleOutlined />
            </template>
            {{ $t('LM001.suspended') }}
          </a-button>
          <a-button
            :class="filterStatus === 'All' ? 'check-btn' : 'uncheck-btn'"
            @click="onFilter('All')"
          >
            <template v-if="filterStatus === 'All'" #icon>
              <CheckCircleOutlined />
            </template>
            {{ $t('LM001.all') }}
          </a-button>
        </div>
      </div>
      <div class="case-list-table">
        <a-table
          class="ant-table-striped"
          :dataSource="cases"
          :columns="columns"
          rowKey="fid"
          :scroll="{ x: 1500 }"
          :rowClassName="tableListRowClassName"
        >
          <template #custonIdTmp="{ text }">
            <span>
              {{ convertCustomId(text) }}
            </span>
          </template>
          <template #cardIdTmp="{ text }">
            <span>
              {{ convertCardId(text) }}
            </span>
          </template>
          <template #rocIdTmp="{ text }">
            <span>
              {{ convertRocId(text) }}
            </span>
          </template>
          <template #myKadIdTmp="{ text }">
            <span>
              {{ convertMyKadId(text) }}
            </span>
          </template>
          <template #mobilePhoneIdTmp="{ text }">
            <span>
              {{ convertMobilePhoneId(text) }}
            </span>
          </template>
          <template #groupTmp="{ text }">
            <span>
              {{ getGroupTitle(text) }}
            </span>
          </template>
          <template #tagsTmp="{ text }">
            <span>
              <a-tag v-for="item in getGroups(text)" :key="item">
                {{ item }}
              </a-tag>
            </span>
          </template>
          <template #tmpModifyTime="{ text }">
            {{ convertTimeFormat(text) }}
          </template>
          <template #tmpEnd="{ text }">
            {{ convertTimeFormatType2(text) }}
          </template>
          <template #tmpGender="{ text }">
            {{ convertGender(text) }}
          </template>
          <template #tmpHeight="{ text }">
            {{ convertHeight(text) }}
          </template>
          <template #tmpState="{ text }">
            {{ convertState(text) }}
          </template>
          <template #tmpEpass="{ text }">
            {{ convertEpass(text) }}
          </template>
          <template #tmpRemark="{ text }">
            <a-popover title="" trigger="click">
              <template #content>
                <div style="max-width: 200px">
                  <p>{{ text }}</p>
                </div>
              </template>
              <a>{{ convertRemark(text) }}</a>
            </a-popover>
          </template>
          <template #operation="{ record }">
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent>
                <BarsOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item :disabled="isEnableCaseEdit">
                    <div @click="clickOpenCaseDetail(record.fid)">
                      {{ $t('LM001.edit') }}
                    </div>
                  </a-menu-item>
                  <a-menu-item :disabled="isEnableCaseEdit">
                    <div @click="clickActivateSuspend(record)">
                      {{
                        record.state === 'ACTIVATED'
                          ? $t('LM001.suspend')
                          : $t('LM001.activate')
                      }}
                    </div>
                  </a-menu-item>
                  <a-menu-item :disabled="isEnableCaseDelete">
                    <div @click="clickDeleteCase(record)">
                      {{ $t('LM001.delete') }}
                    </div>
                  </a-menu-item>
                  <a-menu-item v-if="isShowLinkToMeasurementsPeriod">
                    <router-link
                      :to="{
                        name: 'MeasurementsPeriod',
                        params: {
                          fid: record.fid,
                        },
                        query: {
                          naviType: 'case',
                        },
                      }"
                    >
                      {{ $t('LM001.personalMeasurements') }}
                    </router-link>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-table>
      </div>
    </div>
    <div class="case-modal-area">
      <a-modal
        class="case-case-detail-modal"
        v-model:visible="isShowCaseDetail"
        :title="caseInfoModalTitle"
        :closable="false"
        width="750px"
      >
        <template #footer>
          <div v-if="caseDetailMode == 'INFO'">
            <a-button
              class="btn-confirm-green"
              key="modify"
              @click="clickStartModifyMode"
            >
              {{ $t('LM001.edit') }}
            </a-button>
          </div>
          <div v-if="caseDetailMode == 'MODIFY'">
            <a-button
              class="btn-cancel-gray"
              key="cancel"
              @click="clickCancelModify"
            >
              {{ $t('LM001.cancel') }}
            </a-button>
            <a-button
              class="btn-confirm-green"
              key="submit"
              @click="clickSaveModify"
              :loading="isSaveloading"
            >
              {{ $t('LM001.save') }}
            </a-button>
          </div>
          <div v-if="caseDetailMode == 'CREATE'">
            <a-button
              class="btn-cancel-gray"
              key="cancel"
              @click="clickCancelModify"
            >
              {{ $t('LM001.cancel') }}
            </a-button>
            <a-button
              class="btn-confirm-green"
              key="submit"
              @click="clickSaveCreate"
              :loading="isSaveloading"
            >
              {{ $t('LM001.save') }}
            </a-button>
          </div>
        </template>
        <a-form
          ref="caseFormRef"
          :model="caseDetail"
          :label-col="caseDetailLabelCol"
          :wrapper-col="caseDetailWrapperCol"
          :rules="caseDetailRules"
        >
          <!-- Personal Info-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.personalInfoArea') }}
            </div>
            <a-form-item
              :label="$t('LM001.name')"
              name="name"
              v-if="isWebConfigShowName"
            >
              <a-input
                v-model:value="caseDetail.name"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
              />
            </a-form-item>
            <a-form-item :label="$t('LM001.gender')" name="gender">
              <a-select
                :allowClear="true"
                v-model:value="caseDetail.gender"
                :placeholder="$t('LM001.pleaseChooseGender')"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
              >
                <a-select-option key="M" value="M">
                  {{ $t('LM001.male') }}
                </a-select-option>
                <a-select-option key="F" value="F">
                  {{ $t('LM001.female') }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('LM001.birthday')" name="birthday">
              <a-date-picker
                v-model:value="caseDetail.birthday"
                :format="birthdayDateFormat"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
              />
            </a-form-item>
            <a-form-item :label="$t('LM001.height')" name="height">
              <a-input
                v-model:value="caseDetail.height"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :suffix="getHeightUnit()"
                :placeholder="getHeightPlaceholder()"
              />
            </a-form-item>
          </a-card>
          <!-- ID Info-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.idInfoArea') }}
            </div>
            <div v-if="!isWebConfigShowEPass" class="first-card-warning">
              {{
                isShowCustomIdCardIdRocIdWarning
                  ? $t('LM001.pleaseInputCustomIdCardIdRocId')
                  : ' '
              }}
            </div>
            <a-form-item
              :label="$t('LM001.idCode')"
              name="customId"
              v-if="isWebConfigEnableCustomId"
            >
              <a-input
                v-model:value="caseDetail.customId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.idHint')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputIdCode()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.rocId')"
              name="rocId"
              v-if="isWebConfigShowRocId"
            >
              <a-input
                v-model:value="caseDetail.rocId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.rocIdHint')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputRocId()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.cardNum')"
              name="cardId"
              v-if="isWebConfigShowCardId"
            >
              <a-input
                v-model:value="caseDetail.cardId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.cardNumHint')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputCardNum()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.mobilePhone')"
              name="mobilePhoneId"
              v-if="isWebConfigShowMobilePhoneId"
              :rules="[
                {
                  required: isWebConfigShowEPass,
                  validator: validateIdInfo,
                  trigger: 'blur',
                },
              ]"
            >
              <a-input
                v-model:value="caseDetail.mobilePhoneId"
                :disabled="
                  !isWebConfigEnableMobilePhoneId ||
                  !isEnablePhoneNumberEdit ||
                  (caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus)
                "
                :placeholder="
                  isWebConfigShowMobilePhoneId && isWebConfigShowEPass
                    ? $t('LM001.ePassPhoneNumberHint')
                    : $t('LM001.mobilePhone')
                "
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputMobilePhoneId()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.myKadId')"
              name="myKadId"
              v-if="isWebConfigShowMyKadId"
            >
              <a-input
                v-model:value="caseDetail.myKadId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.myKadId')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputMyKadId()"
              />
            </a-form-item>
          </a-card>
          <!-- status management-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.statusManagementArea') }}
            </div>
            <a-form-item
              :label="$t('LM001.eligibilityStatus')"
              name="eligibilityStatus"
              v-if="isShowEligibilityStatus"
            >
              <a-switch v-model:checked="caseDetail.status" />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.validityPeriod')"
              name="validityPeriod"
            >
              <a-date-picker
                v-model:value="caseDetail.validityPeriod"
                :format="birthdayDateFormat"
                :placeholder="$t('LM001.validityPeriodNone')"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :disabled-date="disableValiditydDate"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.ePassStatus')"
              name="ePassStatus"
              v-if="isShowEPassStatus && isWebConfigShowEPass"
            >
              {{ getEPassLocalTime() }}
            </a-form-item>
            <a-form-item :label="$t('LM001.group')" v-if="isWebConfigShowGroup">
              <a-select
                :allowClear="true"
                v-model:value="caseDetail.group"
                :placeholder="$t('LM001.pleaseChooseGroup')"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :options="caseDetailGroupOptions"
              >
                <a-select-option
                  v-for="item in caseDetailGroupOptions"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-card>
          <!-- other-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.othersArea') }}
            </div>
            <a-form-item
              :label="$t('LM001.phoneNum')"
              name="phoneNumber"
              v-if="isWebConfigShowPhoneNumber"
            >
              <a-input
                v-model:value="caseDetail.phoneNumber"
                :disabled="
                  caseDetailMode == 'INFO' || !isWebConfigEnablePhoneNumber
                "
              />
            </a-form-item>
            <a-form-item :label="$t('LM001.remark')" name="remark">
              <a-textarea
                v-model:value="caseDetail.remark"
                :disabled="caseDetailMode == 'INFO'"
                :rows="4"
                :maxlength="255"
              ></a-textarea>
              <!-- <a-input
              v-model:value="caseDetail.remark"
              :disabled="caseDetailMode == 'INFO'"
            /> -->
            </a-form-item>
          </a-card>
        </a-form>
      </a-modal>
    </div>
    <a-modal v-model:visible="confirmDeleteVisible" title="">
      <template #footer>
        <a-button class="btn-cancel-gray" @click="onClickDeleteCancel">
          {{ $t('LM005.cancel') }}
        </a-button>
        <a-button class="btn-confirm-green" @click="onClickDeleteOk">
          {{ $t('LM005.confirm') }}
        </a-button>
      </template>
      <!-- <p v-text="confirmDeleteMsg"></p> -->
      <div class="text-wrapper" style="white-space: pre-wrap">
        {{ confirmDeleteMsg }}
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { BarsOutlined } from '@ant-design/icons-vue'
  import { message, Modal } from 'ant-design-vue'
  import dayjs, { Dayjs } from 'dayjs'
  import {
    // CloudUploadOutlined,
    // CloudDownloadOutlined,
    TeamOutlined,
    UserAddOutlined,
    // PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  } from '@ant-design/icons-vue'
  import { accountExpiredCheck } from '../common/accountExpiredCheck.js'
  import { createVNode } from 'vue'

  export default {
    name: 'CaseList',
    data() {
      return {
        // columns: [
        //   {
        //     title: 'id',
        //     dataIndex: 'fid',
        //     key: 'fid',
        //   },
        //   {
        //     title: this.$t('LM001.name'),
        //     dataIndex: 'name',
        //     key: 'name',
        //   },
        //   {
        //     title: this.$t('LM001.idCode'),
        //     dataIndex: 'id',
        //     key: 'id',
        //     slots: {
        //       customRender: 'custonIdTmp',
        //     },
        //   },
        //   {
        //     title: this.$t('LM001.cardNum'),
        //     dataIndex: 'id',
        //     key: 'id',
        //     slots: {
        //       customRender: 'cardIdTmp',
        //     },
        //   },
        //   {
        //     title: this.$t('LM001.phoneNum'),
        //     dataIndex: 'phoneNumber',
        //     key: 'phoneNumber',
        //   },
        //   {
        //     title: this.$t('LM001.rocId'),
        //     dataIndex: 'id',
        //     key: 'id',
        //     slots: {
        //       customRender: 'rocIdTmp',
        //     },
        //   },
        //   {
        //     title: this.$t('LM001.group'),
        //     dataIndex: 'group',
        //     key: 'group',
        //     slots: {
        //       customRender: 'groupTmp',
        //     },
        //   },
        //   {
        //     title: this.$t('LM001.modifiedTime'),
        //     dataIndex: 'updated',
        //     key: 'updated',
        //     slots: {
        //       customRender: 'tmpModifyTime',
        //     },
        //   },
        //   {
        //     title: '',
        //     key: 'operation',
        //     slots: {
        //       customRender: 'operation',
        //     },
        //   },
        // ],
        columns: [],
        isShowCaseDetail: false,
        caseFormRef: undefined,
        caseDetailMode: 'MODIFY', // ['INFO', 'MODIFY', 'CREATE']
        caseDetail: {
          name: '',
          customId: '',
          cardId: '',
          rocId: '',
          mobilePhoneId: '',
          myKadId: '',
          phoneNumber: '',
          height: '',
          heightUnit: 'cm',
          birthday: '',
          gender: '',
          group: '',
          remark: '',
          validityPeriod: null,
          status: false,
          ePassIssued: '',
        },
        caseDetailLabelCol: { span: 8 },
        caseDetailWrapperCol: { span: 16 },
        caseAddUserWrapperCol: { span: 18, offset: 6 },
        caseDetailRules: {
          // name: [
          //   {
          //     required: true,
          //     message: this.$t('LM001.pleaseInputName'),
          //     trigger: 'blur',
          //   },
          // ],
          // customId: [
          //   {
          //     required: true,
          //     message: this.$t('LM001.pleaseInputIdName'),
          //     trigger: 'blur',
          //   },
          // ],
          cardId: [{ validator: this.validateIdInfo, trigger: 'blur' }],
          customId: [{ validator: this.validateIdInfo, trigger: 'blur' }],
          rocId: [{ validator: this.validateIdInfo, trigger: 'blur' }],
          // mobilePhoneId: [
          //   {
          //     validator: this.validateMobilePhoneId,
          //     trigger: 'blur',
          //   },
          // ],
          myKadId: [{ validator: this.validateIdInfo, trigger: 'blur' }],
          gender: [{ required: true, message: '' }],
          height: [
            { required: true, validator: this.validateHeight, trigger: 'blur' },
          ],
          birthday: [{ required: true, message: '' }],
        },
        caseDetailGroupOptions: [],
        isSaveloading: false,
        confirmDeleteMsg: '',
        confirmDeleteFid: -1,
        confirmDeleteVisible: false,
        birthdayDateFormat: 'YYYY-MM-DD',
        isEnablePhoneNumberEdit: true,
        isShowCustomIdCardIdRocIdWarning: false,
        heightUnit: 'cm', // 預設公制
        caseDetailEligibilityStatus: true,
        filterStatus: 'Activate', // ['Activate', 'Suspended', 'All']
      }
    },
    components: {
      // CloudUploadOutlined,
      // CloudDownloadOutlined,
      TeamOutlined,
      UserAddOutlined,
      BarsOutlined,
      // PlusOutlined,
      MinusCircleOutlined,
      CheckCircleOutlined,
    },
    methods: {
      ...mapActions({
        getGroupList: 'group/getGroupList',
        getCaseList: 'case/getCaseList',
        createCase: 'case/createCase',
        modifyCaseInfo: 'case/modifyCaseInfo',
        deleteCase: 'case/deleteCase',
        getMeasureThreshold: 'measureThreshold/getMeasureThreshold',
      }),
      updateGroupOptions() {
        this.caseDetailGroupOptions = []
        if (!this.caseDetailGroupOptions) {
          return
        }

        this.groups.forEach((item) => {
          this.caseDetailGroupOptions.push({
            value: item.groupId.toString(),
            label: item.title,
          })
        })
      },
      goToGroupManagement() {
        this.$router.push('/caseManagement/groupManagement')
      },
      convertCustomId(data) {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].idType === 'CUSTOM_ID') {
              return data[i].idValue
            }
          }
        }
        return ''
      },
      convertCardId(data) {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].idType === 'NFC_CARD_NUMBER') {
              return data[i].idValue
            }
          }
        }
        return ''
      },
      convertRocId(data) {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].idType === 'ROC_ID') {
              return data[i].idValue
            }
          }
        }
        return ''
      },
      convertMobilePhoneId(data) {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].idType === 'MOBILE_PHONE') {
              return data[i].idValue
            }
          }
        }
        return ''
      },
      convertMyKadId(data) {
        if (data && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (
              data[i].idType === 'My_Kad_ID' ||
              data[i].idType === 'MY_KAD_ID'
            ) {
              return data[i].idValue
            }
          }
        }
        return ''
      },
      convertTimeFormat(text) {
        if (text) {
          return dayjs(text).format('YYYY/MM/DD')
        } else {
          return ''
        }
      },
      convertTimeFormatType2(text) {
        if (text) {
          return dayjs(text).format('YYYY/MM/DD')
        } else {
          return this.$t('LM001.validityPeriodNone')
        }
      },
      convertEpass(text) {
        if (text) {
          return (
            this.$t('LM001.calimed') +
            '(' +
            dayjs(text).format('YYYY/MM/DD') +
            ')'
          )
        } else {
          return this.$t('LM001.notReceived')
        }
      },
      convertGender(text) {
        if (text === 'M') {
          return this.$t('LM001.male')
        } else if (text === 'F') {
          return this.$t('LM001.female')
        } else {
          return ''
        }
      },
      convertRemark(text) {
        if (text) {
          return text.length > 11 ? text.substr(0, 11 - 1) + '...' : text
        } else {
          return text
        }
      },
      convertHeight(text) {
        if (
          this.measureThreshold?.stature?.unit === 'ft&in' &&
          typeof text === 'string'
        ) {
          return text.replace('&', "'") + '"'
        } else {
          return text
        }
      },
      convertState(text) {
        if (text === 'SUSPENDED') {
          return this.$t('LM001.suspended')
        } else if (text === 'ACTIVATED') {
          return this.$t('LM001.activate')
        } else {
          return text
        }
      },
      getGroupTitle(group) {
        if (group && group.title) {
          return group.title
        }
        return ''
      },
      getGroups(group) {
        // if (groups && groups.length > 0) {
        //   let groupList = []
        //   groups.forEach((item) => {
        //     groupList.push(item.title)
        //   })
        //   return groupList
        // }
        if (group) {
          const groupList = []
          groupList.push(group.title)
          return groupList
        }
        return []
      },
      clickCreateCase() {
        this.caseDetailMode = 'CREATE'
        this.isEnablePhoneNumberEdit = true
        this.isShowCaseDetail = true
        this.caseDetail = {}
        if (this.caseFormRef) {
          this.caseFormRef.clearValidate()
        }
        this.isShowCustomIdCardIdRocIdWarning = true
      },
      clickStartModifyMode() {
        this.caseDetailMode = 'MODIFY'
        this.caseFormRef.clearValidate()
        this.isShowCustomIdCardIdRocIdWarning = false
        //console.log('clickStartModifyMode')
      },
      async clickActivateSuspend(record) {
        try {
          const patchBody = {
            subjects: [
              {
                fid: record.fid,
                ids: [
                  {
                    from: {
                      idType: record.id[0].idType,
                      idValue: record.id[0].idValue,
                    },
                    to: [],
                  },
                ],
              },
            ],
          }

          record.id.forEach((idItem) => {
            patchBody.subjects[0].ids[0].to.push({
              idType: idItem.idType,
              idValue: idItem.idValue,
            })
          })

          // ACTIVATED, SUSPENDED
          if (record.state === 'ACTIVATED') {
            patchBody.subjects[0].activate = false
          } else {
            patchBody.subjects[0].activate = true
          }

          this.isSaveloading = true
          await this.modifyCaseInfo(patchBody)
          this.isSaveloading = false
        } catch (e) {
          console.log('error', e)
        }
        console.log('clickActivateSuspend')
      },
      clickDeleteCase(record) {
        this.confirmDeleteMsg =
          this.$t('LM001.confirmDeleteMessage') && record.name
            ? this.$t('LM001.confirmDeleteMessage').replace(
                /REPLACE-USER-NAME/,
                record.name.toString()
              )
            : ''
        this.confirmDeleteFid = record.fid
        this.confirmDeleteVisible = true

        // let self = this
        // this.$confirm({
        //   //title: this.$t('LM005.confirmDelete'),
        //   content: '是否確認刪除@USER-NAME？<br>刪除後量測紀錄也將一併刪除。',
        //   // content:
        //   //   this.$t('LM001.confirmDeleteMessage') && record.name
        //   //     ? this.$t('LM001.confirmDeleteMessage').replace(
        //   //         /@USER-NAME/,
        //   //         record.name.toString()
        //   //       )
        //   //     : '',
        //   okText: this.$t('LM005.confirm'),
        //   okType: 'danger',
        //   cancelText: this.$t('LM005.cancel'),
        //   async onOk() {
        //     await self.deleteCase(record.fid)
        //     //message.info(fid)
        //   },
        //   onCancel() {
        //     //console.log('cancel')
        //   },
        // })
      },
      async onClickDeleteOk() {
        if (this.confirmDeleteFid > 0) {
          await this.deleteCase(this.confirmDeleteFid)
          this.confirmDeleteFid = -1
        }
        this.confirmDeleteVisible = false
        //message.info('delete test')
      },
      onClickDeleteCancel() {
        this.confirmDeleteFid = -1
        this.confirmDeleteVisible = false
      },
      clickCancelModify() {
        // this.isShowCaseDetail = false
        // this.caseDetailMode = 'INFO'
        // this.caseDetail = {}
        this.caseDetailMode = 'MODIFY'
        this.isShowCaseDetail = false
        console.log('clickCancelModify')
      },
      // async validateCardId(rule, value) {
      // async validateCardId() {
      //   if (
      //     !this.caseDetail.customId &&
      //     !this.caseDetail.cardId &&
      //     !this.caseDetail.rocId &&
      //     !this.caseDetail.mobilePhoneId &&
      //     !this.caseDetail.myKadId
      //   ) {
      //     this.isShowCustomIdCardIdRocIdWarning = true
      //     return Promise.reject('')
      //     // return Promise.reject(this.$t('LM001.pleaseInputCustomIdCardIdRocId'))
      //   } else {
      //     this.isShowCustomIdCardIdRocIdWarning = false
      //     return Promise.resolve()
      //   }
      // },
      // async validateCustomId() {
      //   if (
      //     !this.caseDetail.customId &&
      //     !this.caseDetail.cardId &&
      //     !this.caseDetail.rocId &&
      //     !this.caseDetail.mobilePhoneId &&
      //     !this.caseDetail.myKadId
      //   ) {
      //     this.isShowCustomIdCardIdRocIdWarning = true
      //     return Promise.reject('')
      //     // return Promise.reject(this.$t('LM001.pleaseInputCustomIdCardIdRocId'))
      //   } else {
      //     this.isShowCustomIdCardIdRocIdWarning = false
      //     return Promise.resolve()
      //   }
      // },
      // async validateRocId() {
      //   if (
      //     !this.caseDetail.customId &&
      //     !this.caseDetail.cardId &&
      //     !this.caseDetail.rocId &&
      //     !this.caseDetail.mobilePhoneId &&
      //     !this.caseDetail.myKadId
      //   ) {
      //     this.isShowCustomIdCardIdRocIdWarning = true
      //     return Promise.reject('')
      //     // return Promise.reject(this.$t('LM001.pleaseInputCustomIdCardIdRocId'))
      //   } else {
      //     this.isShowCustomIdCardIdRocIdWarning = false
      //     return Promise.resolve()
      //   }
      // },
      // async validateMobilePhoneId() {
      //   if (
      //     !this.caseDetail.customId &&
      //     !this.caseDetail.cardId &&
      //     !this.caseDetail.rocId &&
      //     !this.caseDetail.mobilePhoneId &&
      //     !this.caseDetail.myKadId
      //   ) {
      //     this.isShowCustomIdCardIdRocIdWarning = true
      //     return Promise.reject('')
      //     // return Promise.reject(this.$t('LM001.pleaseInputCustomIdCardIdRocId'))
      //   } else {
      //     this.isShowCustomIdCardIdRocIdWarning = false
      //     return Promise.resolve()
      //   }
      // },
      // async validateMyKadId() {
      //   if (
      //     !this.caseDetail.customId &&
      //     !this.caseDetail.cardId &&
      //     !this.caseDetail.rocId &&
      //     !this.caseDetail.mobilePhoneId &&
      //     !this.caseDetail.myKadId
      //   ) {
      //     this.isShowCustomIdCardIdRocIdWarning = true
      //     return Promise.reject('')
      //     // return Promise.reject(this.$t('LM001.pleaseInputCustomIdCardIdRocId'))
      //   } else {
      //     this.isShowCustomIdCardIdRocIdWarning = false
      //     return Promise.resolve()
      //   }
      // },
      async validateIdInfo() {
        if (
          !this.caseDetail.customId &&
          !this.caseDetail.cardId &&
          !this.caseDetail.rocId &&
          !this.caseDetail.mobilePhoneId &&
          !this.caseDetail.myKadId
        ) {
          this.isShowCustomIdCardIdRocIdWarning = true
          return Promise.reject('')
          // return Promise.reject(this.$t('LM001.pleaseInputCustomIdCardIdRocId'))
        } else if (
          this.isWebConfigShowEPass &&
          !this.caseDetail.mobilePhoneId
        ) {
          this.isShowCustomIdCardIdRocIdWarning = true
          return Promise.reject('')
        } else {
          this.isShowCustomIdCardIdRocIdWarning = false
          return Promise.resolve()
        }
      },
      async validateHeight() {
        const re = /^\d'\s?(\d{1,2}")?$/
        if (
          this.measureThreshold?.stature?.unit === 'ft&in' &&
          re.test(this.caseDetail.height) === false
        ) {
          return Promise.reject('')
        } else if (
          this.measureThreshold?.stature?.unit !== 'ft&in' &&
          isNaN(Number(this.caseDetail.height))
        ) {
          return Promise.reject('')
        } else {
          return Promise.resolve()
        }
      },
      async clickSaveModify() {
        try {
          this.caseFormRef = this.$refs.caseFormRef
          await this.caseFormRef.validate()

          if (this.cases[this.caseDetail.idx].id.length <= 0) {
            return
          }

          const patchBody = {
            subjects: [
              {
                fid: this.caseDetail.fid,
                name: this.caseDetail.name,
                phoneNumber: this.caseDetail.phoneNumber,
                // ids: [
                //   {
                //     from: {
                //       idType: 'CUSTOM_ID',
                //       idValue: this.convertCustomId(
                //         this.cases[this.caseDetail.idx].id
                //       ),
                //     },
                //     to: [
                //       {
                //         idType: 'CUSTOM_ID',
                //         idValue: this.caseDetail.customId,
                //       },
                //     ],
                //   },
                // ],
                ids: [
                  {
                    from: {
                      idType: this.cases[this.caseDetail.idx].id[0].idType,
                      idValue: this.cases[this.caseDetail.idx].id[0].idValue,
                    },
                    to: [],
                  },
                ],
                groupId: null,
              },
            ],
          }
          if (this.caseDetail.customId) {
            patchBody.subjects[0].ids[0].to.push({
              idType: 'CUSTOM_ID',
              idValue: this.caseDetail.customId,
            })
          }
          if (this.caseDetail.cardId) {
            patchBody.subjects[0].ids[0].to.push({
              idType: 'NFC_CARD_NUMBER',
              idValue: this.caseDetail.cardId,
            })
          }
          if (this.caseDetail.rocId) {
            patchBody.subjects[0].ids[0].to.push({
              idType: 'ROC_ID',
              idValue: this.caseDetail.rocId,
            })
          }
          if (this.caseDetail.mobilePhoneId) {
            patchBody.subjects[0].ids[0].to.push({
              idType: 'MOBILE_PHONE',
              idValue: this.caseDetail.mobilePhoneId,
            })
          }
          if (this.caseDetail.myKadId) {
            patchBody.subjects[0].ids[0].to.push({
              idType: 'MY_KAD_ID',
              idValue: this.caseDetail.myKadId,
            })
          }
          if (this.caseDetail.group) {
            let groupId = this.caseDetail.group
            if (typeof this.caseDetail.group === 'string') {
              groupId = parseInt(this.caseDetail.group, 10)
            }
            patchBody.subjects[0].groupId = groupId
          }
          if (this.caseDetail.birthday) {
            patchBody.subjects[0].birthday = dayjs(
              this.caseDetail.birthday
            ).format('YYYY-MM-DD')
          }
          if (this.caseDetail.gender) {
            patchBody.subjects[0].gender = this.caseDetail.gender
          }
          if (this.caseDetail.height) {
            if (this.heightUnit === 'cm') {
              const iHeight = parseInt(this.caseDetail.height, 10)
              if (isNaN(iHeight) === false) {
                patchBody.subjects[0].height = iHeight
                patchBody.subjects[0].heightUnit = this.heightUnit
              }
            } else if (this.heightUnit == 'ft&in') {
              patchBody.subjects[0].height = this.caseDetail.height
                .replace('"', '')
                .replace("'", '&')
              patchBody.subjects[0].heightUnit = this.heightUnit
            }
          }
          if (this.caseDetail.remark) {
            patchBody.subjects[0].remark = this.caseDetail.remark
          }
          if (this.caseDetail.validityPeriod) {
            patchBody.subjects[0].end = dayjs(this.caseDetail.validityPeriod)
              .endOf('day')
              .unix()
            // patchBody.subjects[0].end = dayjs(
            //   this.caseDetail.validityPeriod
            // ).format('YYYY-MM-DD')
          } else {
            patchBody.subjects[0].end = null
          }

          // ACTIVATED, SUSPENDED
          if (this.caseDetail.status === true) {
            patchBody.subjects[0].activate = true
          } else {
            patchBody.subjects[0].activate = false
          }

          this.isSaveloading = true
          await this.modifyCaseInfo(patchBody)
          this.isSaveloading = false
          this.caseDetailMode = 'MODIFY'
          this.isShowCaseDetail = false
          this.caseDetail = {}
        } catch (e) {
          console.log('error', e)
        }
        console.log('clickSaveModify')
      },
      async clickSaveCreate() {
        try {
          this.caseFormRef = this.$refs.caseFormRef
          await this.caseFormRef.validate()

          const postBody = {
            subjects: [
              {
                name: this.caseDetail.name,
                phoneNumber: this.caseDetail.phoneNumber,
                id: [],
              },
            ],
          }
          if (this.caseDetail.customId) {
            postBody.subjects[0].id.push({
              idType: 'CUSTOM_ID',
              idValue: this.caseDetail.customId,
            })
          }
          if (this.caseDetail.cardId) {
            postBody.subjects[0].id.push({
              idType: 'NFC_CARD_NUMBER',
              idValue: this.caseDetail.cardId,
            })
          }
          if (this.caseDetail.rocId) {
            postBody.subjects[0].id.push({
              idType: 'ROC_ID',
              idValue: this.caseDetail.rocId,
            })
          }
          if (this.caseDetail.mobilePhoneId) {
            postBody.subjects[0].id.push({
              idType: 'MOBILE_PHONE',
              idValue: this.caseDetail.mobilePhoneId,
            })
          }
          if (this.caseDetail.myKadId) {
            postBody.subjects[0].id.push({
              idType: 'MY_KAD_ID',
              idValue: this.caseDetail.myKadId,
            })
          }

          if (postBody.subjects[0].id.length <= 0) {
            return
          }

          if (this.caseDetail.group) {
            let groupId = this.caseDetail.group
            if (typeof this.caseDetail.group === 'string') {
              groupId = parseInt(this.caseDetail.group, 10)
            }
            postBody.subjects[0].groupId = groupId
          }
          if (this.caseDetail.birthday) {
            postBody.subjects[0].birthday = dayjs(
              this.caseDetail.birthday
            ).format('YYYY-MM-DD')
          }
          if (this.caseDetail.gender) {
            postBody.subjects[0].gender = this.caseDetail.gender
          }
          if (this.caseDetail.height) {
            if (this.heightUnit === 'cm') {
              const iHeight = parseInt(this.caseDetail.height, 10)
              if (isNaN(iHeight) === false) {
                postBody.subjects[0].height = iHeight
                postBody.subjects[0].heightUnit = this.heightUnit
              }
            } else if (this.heightUnit == 'ft&in') {
              postBody.subjects[0].height = this.caseDetail.height
                .replace('"', '')
                .replace("'", '&')
              postBody.subjects[0].heightUnit = this.heightUnit
            }
          }
          // if (this.caseDetail.height) {
          //   const iHeight = parseInt(this.caseDetail.height, 10)
          //   if (isNaN(iHeight) === false) {
          //     postBody.subjects[0].height = iHeight
          //   }
          // }
          // if (this.caseDetail.heightUnit) {
          //   postBody.subjects[0].heightUnit = this.caseDetail.heightUnit
          // }
          if (this.caseDetail.remark) {
            postBody.subjects[0].remark = this.caseDetail.remark
          }
          if (this.caseDetail.validityPeriod) {
            postBody.subjects[0].end = dayjs(this.caseDetail.validityPeriod)
              .endOf('day')
              .unix()
            // postBody.subjects[0].end = dayjs(
            //   this.caseDetail.validityPeriod
            // ).format('YYYY-MM-DD')
          } else {
            // postBody.subjects[0].end = null
          }

          // ACTIVATED, SUSPENDED
          // if (this.caseDetail.status === true) {
          //   postBody.subjects[0].state = true
          // } else {
          //   postBody.subjects[0].state = false
          // }

          console.log(postBody.subjects[0])

          this.isSaveloading = true
          await this.createCase(postBody)
          this.isSaveloading = false

          this.isShowCaseDetail = false
          this.caseDetailMode = 'MODIFY'
          this.caseDetail = {}
        } catch (e) {
          console.log('error', e)
        }
        console.log('clickSaveCreate')
      },
      clickOpenCaseDetail(fid) {
        this.caseDetailMode = 'MODIFY'
        this.isEnablePhoneNumberEdit = true

        this.isShowCustomIdCardIdRocIdWarning = false

        const idx = this.cases.findIndex((element) => {
          if (element.fid === parseInt(fid, 10)) {
            return true
          } else {
            return false
          }
        })
        if (idx >= 0) {
          this.caseDetail = {}
          this.caseDetail.idx = idx
          this.caseDetail.fid = this.cases[idx].fid
          this.caseDetail.name = this.cases[idx].name
          this.caseDetail.customId = this.convertCustomId(this.cases[idx].id)

          this.caseDetail.cardId = this.convertCardId(this.cases[idx].id)

          this.caseDetail.rocId = this.convertRocId(this.cases[idx].id)

          this.caseDetail.mobilePhoneId = this.convertMobilePhoneId(
            this.cases[idx].id
          )
          if (this.isWebConfigShowEPass && this.caseDetail.mobilePhoneId) {
            this.isEnablePhoneNumberEdit = false
          }

          this.caseDetail.myKadId = this.convertMyKadId(this.cases[idx].id)

          this.caseDetail.phoneNumber = this.cases[idx].phoneNumber
          if (this.cases[idx].group) {
            this.caseDetail.group = this.cases[idx].group.id.toString()
          } else {
            this.caseDetail.group = ''
          }
          if (this.cases[idx].gender) {
            this.caseDetail.gender = this.cases[idx].gender
          }
          if (this.cases[idx].birthday) {
            this.caseDetail.birthday = this.cases[idx].birthday
          }
          if (this.cases[idx].height) {
            if (this.measureThreshold?.stature?.unit === 'ft&in') {
              this.caseDetail.height =
                this.cases[idx].height.replace('&', "'") + '"'
            } else {
              this.caseDetail.height = this.cases[idx].height
            }
          }
          if (this.cases[idx].remark) {
            this.caseDetail.remark = this.cases[idx].remark
          }
          if (this.cases[idx].end) {
            this.caseDetail.validityPeriod = this.cases[idx].end
          } else {
            this.caseDetail.validityPeriod = null
          }

          // ACTIVATED, SUSPENDED
          if (this.cases[idx]?.state === 'ACTIVATED') {
            this.caseDetail.status = true
            this.caseDetailEligibilityStatus = true
          } else {
            this.caseDetail.status = false
            this.caseDetailEligibilityStatus = false
          }

          if (this.cases[idx]?.ePassIssued) {
            this.caseDetail.ePassIssued = this.cases[idx].ePassIssued
          } else {
            this.caseDetail.ePassIssued = ''
          }

          this.isShowCaseDetail = true
        } else {
          message.info('Data not found')
        }
      },
      mapCaseListColumn(id) {
        let retData = {}
        switch (id) {
          case 'id':
            retData = {
              title: 'id',
              dataIndex: 'fid',
              key: 'fid',
              width: '100px',
              className: 'column-title-center',
            }
            return retData
          case 'name':
            retData = {
              title: this.$t('LM001.name'),
              dataIndex: 'name',
              key: 'name',
              width: '100px',
              className: 'column-title-center',
            }
            return retData
          case 'cid':
            retData = {
              title: this.$t('LM001.idCode'),
              dataIndex: 'id',
              key: 'id',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'custonIdTmp',
              },
            }
            return retData
          case 'my_kad_id':
            retData = {
              title: this.$t('LM001.myKadId'),
              dataIndex: 'id',
              key: 'id',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'myKadIdTmp',
              },
            }
            return retData
          case 'mobile_otp':
            retData = {
              title: this.$t('LM001.mobilePhone'),
              dataIndex: 'id',
              key: 'id',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'mobilePhoneIdTmp',
              },
            }
            return retData
          case 'nfc_no':
            retData = {
              title: this.$t('LM001.cardNum'),
              dataIndex: 'id',
              key: 'id',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'cardIdTmp',
              },
            }
            return retData
          case 'mobile':
            retData = {
              title: this.$t('LM001.phoneNum'),
              dataIndex: 'phoneNumber',
              key: 'phoneNumber',
              width: '150px',
              className: 'column-title-center',
            }
            return retData
          case 'roc_id':
            retData = {
              title: this.$t('LM001.rocId'),
              dataIndex: 'id',
              key: 'id',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'rocIdTmp',
              },
            }
            return retData
          case 'group':
            retData = {
              title: this.$t('LM001.group'),
              dataIndex: 'group',
              key: 'group',
              width: '100px',
              className: 'column-title-center',
              slots: {
                customRender: 'groupTmp',
              },
            }
            return retData
          case 'modified':
            retData = {
              title: this.$t('LM001.modifiedTime'),
              dataIndex: 'updated',
              key: 'updated',
              width: '130px',
              className: 'column-title-center',
              slots: {
                customRender: 'tmpModifyTime',
              },
            }
            return retData
          case 'height':
            retData = {
              title: this.$t('LM001.height'),
              dataIndex: 'height',
              key: 'height',
              width: '80px',
              className: 'column-title-center',
              slots: {
                customRender: 'tmpHeight',
              },
            }
            return retData
          case 'birthday':
            retData = {
              title: this.$t('LM001.birthday'),
              dataIndex: 'birthday',
              key: 'birthday',
              width: '130px',
              className: 'column-title-center',
            }
            return retData
          case 'gender':
            retData = {
              title: this.$t('LM001.gender'),
              dataIndex: 'gender',
              key: 'gender',
              width: '80px',
              className: 'column-title-center',
              slots: {
                customRender: 'tmpGender',
              },
            }
            return retData
          case 'remark':
            retData = {
              title: this.$t('LM001.remark'),
              dataIndex: 'remark',
              key: 'remark',
              width: '200px',
              className: 'column-title-center',
              slots: {
                customRender: 'tmpRemark',
              },
            }
            return retData
          case 'end':
            retData = {
              title: this.$t('LM001.validityPeriod'),
              dataIndex: 'end',
              key: 'end',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'tmpEnd',
              },
            }
            return retData
          // case 'state':
          //   retData = {
          //     title: this.$t('LM001.eligibilityStatus'),
          //     dataIndex: 'state',
          //     key: 'state',
          //     width: '110px',
          //     className: 'column-title-center',
          //     slots: {
          //       customRender: 'tmpState',
          //     },
          //   }
          //   return retData
          case 'ePassIssued':
            retData = {
              title: this.$t('LM001.ePassStatus'),
              dataIndex: 'ePassIssued',
              key: 'ePassIssued',
              width: '150px',
              className: 'column-title-center',
              slots: {
                customRender: 'tmpEpass',
              },
            }
            return retData
          default:
            return null
        }
      },
      removeInputIdCode() {
        if (
          this.caseDetailMode == 'MODIFY' &&
          !this.caseDetailEligibilityStatus
        ) {
          return
        }
        this.caseDetail.customId = ''
      },
      removeInputRocId() {
        if (
          this.caseDetailMode == 'MODIFY' &&
          !this.caseDetailEligibilityStatus
        ) {
          return
        }
        this.caseDetail.rocId = ''
      },
      removeInputCardNum() {
        if (
          this.caseDetailMode == 'MODIFY' &&
          !this.caseDetailEligibilityStatus
        ) {
          return
        }
        this.caseDetail.cardId = ''
      },
      removeInputMobilePhoneId() {
        if (
          this.caseDetailMode == 'MODIFY' &&
          !this.caseDetailEligibilityStatus
        ) {
          return
        }
        if (this.isEnablePhoneNumberEdit === false) {
          const that = this
          Modal.confirm({
            title: this.$t('LM001.deleteEpassPhoneNumberTitle'),
            icon: createVNode(ExclamationCircleOutlined),
            content: this.$t('LM001.deleteEpassPhoneNumberWarning'),
            okText: this.$t('LM005.confirm'),
            okType: 'danger',
            cancelText: this.$t('LM005.cancel'),
            onOk() {
              that.isEnablePhoneNumberEdit = true
              that.caseDetail.mobilePhoneId = ''
            },
            onCancel() {},
          })
        } else {
          this.caseDetail.mobilePhoneId = ''
        }
      },
      removeInputMyKadId() {
        if (
          this.caseDetailMode == 'MODIFY' &&
          !this.caseDetailEligibilityStatus
        ) {
          return
        }
        this.caseDetail.myKadId = ''
      },

      getHeightUnit() {
        if (this.measureThreshold?.stature?.unit === 'ft&in') {
          // ft&in
          this.heightUnit = 'ft&in'
          return this.$t('MS005.ft')
        } else {
          // 預設公制
          this.heightUnit = 'cm'
          return this.$t('MS005.cm')
        }
      },
      getHeightPlaceholder() {
        if (this.measureThreshold?.stature?.unit === 'ft&in') {
          return '5\'7"'
        } else {
          // 預設公制
          return '170'
        }
      },
      getEPassLocalTime() {
        if (this.caseDetail.ePassIssued) {
          const str = this.$t('LM001.ePassStatusTrue')
          const strDate = dayjs(this.caseDetail.ePassIssued).format(
            'YYYY-MM-DD'
          )
          return `${str}(${strDate})`
        } else {
          return this.$t('LM001.ePassStatusFalse')
        }
      },
      tableListRowClassName(record, index) {
        if (record.state === 'SUSPENDED') {
          return 'table-striped'
        }

        return null
      },
      onFilter(filter) {
        this.filterStatus = filter
        this.getCaseList(this.filterStatus)
      },
      disableValiditydDate(current) {
        const maxDate = dayjs(this.expiredTs).endOf('day')
        const today = dayjs().startOf('day')
        return current && (current <= today || current > maxDate)
      },
    },
    computed: {
      ...mapGetters({
        groups: 'group/groups',
        cases: 'case/cases',
        expiredTs: 'user/expiredTs',
        webConfig: 'user/webConfig',
        measureThreshold: 'measureThreshold/measureThreshold',
      }),
      caseInfoModalTitle() {
        if (this.caseDetailMode === 'INFO') {
          return this.$t('LM001.caseInfo')
        } else if (this.caseDetailMode === 'MODIFY') {
          return this.$t('LM001.editInfo')
        } else if (this.caseDetailMode === 'CREATE') {
          return this.$t('LM001.caseInfo')
        } else {
          return this.$t('LM001.caseInfo')
        }
      },
      isShowLinkToMeasurementsPeriod() {
        let retData = true
        this.webConfig.forEach((conf) => {
          if (conf.id === 'health_data' && conf.privilege.show === false) {
            retData = false
          }
        })
        return retData
      },
      isWebConfigEnableName() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'name') {
                  return caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isWebConfigEnableCustomId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'CUSTOM_ID') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigEnableCardId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'NFC_CARD_NUMBER') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigEnableRocId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'ROC_ID') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigEnableMobilePhoneId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'MOBILE_PHONE') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigEnableMyKadId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (
                this.webConfig[i].idTypes[j].key === 'MY_KAD_ID' ||
                this.webConfig[i].idTypes[j].key === 'My_Kad_ID'
              ) {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigEnablePhoneNumber() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'mobile') {
                  return caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isWebConfigEnableGroup() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'group') {
                  return caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isWebConfigShowName() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'name') {
                  return caseColumn[j].privilege.show
                }
              }
            }
          }
        }
        return true
      },
      isWebConfigShowCustomId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'CUSTOM_ID') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigShowCardId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'NFC_CARD_NUMBER') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigShowRocId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'ROC_ID') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigShowMobilePhoneId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (this.webConfig[i].idTypes[j].key === 'MOBILE_PHONE') {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigShowMyKadId() {
        let checkIdTypeConfig = false
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (this.webConfig[i].idTypes && this.webConfig[i].idTypes.length) {
            for (let j = 0; j < this.webConfig[i].idTypes.length; j++) {
              if (
                this.webConfig[i].idTypes[j].key === 'MY_KAD_ID' ||
                this.webConfig[i].idTypes[j].key === 'My_Kad_ID'
              ) {
                checkIdTypeConfig = true
              }
            }
          }
        }
        if (checkIdTypeConfig === false) {
          // 找不到此設定,代表無此欄位權限
          return false
        }
        return true
      },
      isWebConfigShowPhoneNumber() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'mobile') {
                  return caseColumn[j].privilege.show
                }
              }
            }
          }
        }
        return true
      },
      isWebConfigShowGroup() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'group') {
                  return caseColumn[j].privilege.show
                }
              }
            }
          }
        }
        return true
      },
      isWebConfigShowEPass() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'vas_epass' &&
            this.webConfig[i].privilege.show === true
          ) {
            console.log(
              'isWebConfigShowEPass',
              this.webConfig[i].privilege.show
            )
            return true
          }
        }
        console.log('isWebConfigShowEPass', false)
        return false
      },
      isEnableCreateCase() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'create_subject') {
                  return !caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isEnableGroupManagement() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'group_mgnt') {
                  return !caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isEnableCaseEdit() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'subject_edit') {
                  return !caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isEnableCaseDelete() {
        for (let i = 0; i < this.webConfig.length > 0; i++) {
          if (
            this.webConfig[i].id === 'subject_management' &&
            this.webConfig[i].privilege.show === true
          ) {
            const caseColumn = this.webConfig[i].content
            if (caseColumn) {
              for (let j = 0; j < caseColumn.length; j++) {
                if (caseColumn[j].id === 'subject_delete') {
                  return !caseColumn[j].privilege.enable
                }
              }
            }
          }
        }
        return true
      },
      isShowEligibilityStatus() {
        if (this.caseDetailMode === 'MODIFY') {
          return true
        }
        return false
      },
      isShowEPassStatus() {
        if (this.caseDetailMode === 'MODIFY') {
          return true
        }
        return false
      },
    },
    async mounted() {
      accountExpiredCheck(this.expiredTs)
      this.getMeasureThreshold()
      this.getCaseList(this.filterStatus)
      this.getGroupList()
      let wishColumn = []
      for (let i = 0; i < this.webConfig.length; i++) {
        if (this.webConfig[i].id === 'subject_management') {
          wishColumn = this.webConfig[i].content || []
          break
        }
      }

      if (wishColumn.length > 0) {
        wishColumn.sort(function (a, b) {
          return a.order - b.order
        })

        this.columns = []
        wishColumn.forEach((col) => {
          if (col?.privilege?.show === true) {
            const colData = this.mapCaseListColumn(col.id)
            if (colData) {
              this.columns.push(colData)
            }
          }
        })
        // TODO(Dio), 目前權限設定沒有此欄位，先放到最後
        const colEnd = this.mapCaseListColumn('end')
        if (colEnd) {
          this.columns.push(colEnd)
        }
        const colState = this.mapCaseListColumn('state')
        if (colState) {
          this.columns.push(colState)
        }
        if (this.isWebConfigShowEPass === true) {
          const colEpass = this.mapCaseListColumn('ePassIssued')
          if (colEpass) {
            this.columns.push(colEpass)
          }
        }
        // const colBirthdayData = this.mapCaseListColumn('birthday')
        // if (colBirthdayData) {
        //   this.columns.push(colBirthdayData)
        // }
        // const colGenderData = this.mapCaseListColumn('gender')
        // if (colGenderData) {
        //   this.columns.push(colGenderData)
        // }
        // const colHeightData = this.mapCaseListColumn('height')
        // if (colHeightData) {
        //   this.columns.push(colHeightData)
        // }
        // const colRemark = this.mapCaseListColumn('remark')
        // if (colRemark) {
        //   this.columns.push(colRemark)
        // }

        if (this.columns.length > 0) {
          this.columns.push({
            title: '',
            key: 'operation',
            width: '50px',
            slots: {
              customRender: 'operation',
            },
          })
        }
      }
    },
    watch: {
      groups: function () {
        this.updateGroupOptions()
      },
    },
  }
</script>
<style scoped lang="less">
  th.column-title-center.ant-table-row-cell-break-word,
  td.column-title-center.ant-table-row-cell-break-word {
    text-align: center !important;
  }
  .ant-table-striped :deep(.table-striped) td {
    background-color: #f5f5f5;
  }

  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .case-list-container {
    background-color: #e5e5e5;
    padding-top: 24px;
    padding-bottom: 24px;

    .case-list-area {
      //margin-top: 24px;
      margin-left: 32px;
      margin-right: 32px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;

      .case-list-top {
        height: 75px;
        .clear-fix-float();

        .example-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .import-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .new-case-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .group-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .filter-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
          .check-btn {
            // margin-top: 50px;
            margin-left: 10px;
            // width: 135px;
            height: 32px;
            border-radius: 4px;
            background: #4ebaa8;
            text-align: center;
            color: var(--white-white, #fff);
            /* body/sans-serif/desktop/default/body-s-default */
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .uncheck-btn {
            // margin-top: 50px;
            margin-left: 10px;
            // width: 135px;
            height: 32px;
            border-radius: 4px;
            background: #bdbdbd;
            text-align: center;
            color: var(--white-white, #fff);
            /* body/sans-serif/desktop/default/body-s-default */
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .case-list-table {
        .clear-fix-float();
      }
    }
  }

  //.case-case-detail-modal {
  .first-card-in-form {
    // margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .second-card-in-form {
    margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .first-card-label-flex-container {
    display: flex;
  }
  .first-card-warning {
    // padding-top: 10px;
    height: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ea6363;
  }
  .card-label-in-form {
    width: max-content;
    margin-left: -20px;
    // margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: #4dbaa7;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    /* identical to box height */

    // display: flex;
    // align-items: center;
    text-align: right;

    color: #ffffff;
  }
  .second-card-label-in-form {
    width: max-content;
    margin-left: -20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: #4dbaa7;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    /* identical to box height */

    // display: flex;
    // align-items: center;
    text-align: right;

    color: #ffffff;
  }
  .btn-confirm-green {
    width: 135px;
    background: #4ebaa8;
    border-radius: 4px;
  }
  .btn-cancel-gray {
    width: 135px;
    background: #bdbdbd;
    border-radius: 4px;
  }
  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }
  //}
</style>
